.no-data-message-billing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.no-data-message-billing-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.no-data-message-billing-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #484848;
  margin: 0;
  padding: 0;
}

.no-data-message-billing-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #484848;
  margin: 0;
  padding: 0;
}
