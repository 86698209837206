.integration {
  box-shadow: none !important;
  margin-top: 0 !important;
  .title:first-child {
    margin-top: 0px !important;
  }
  .title {
    background-color: #f8f8f8 !important;
    margin-top: 20px !important;
    border-top: none !important;
    border-radius: 12px;
  }
  .title.active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .content {
    background-color: #f8f8f8;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 15px !important;
  }
  .integration-count {
    background-color: #2269ad;
    padding: 0px 10px 2px;
    border-radius: 24px;
    color: #fff;
    font-size: 12px;
    font-size: 12px;
    vertical-align: text-top;
  }
  .kinderConnectToggle {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .ui.table.attendance-log-table {
    font-size: 14px;
    font-weight: 300;
    font-size: normal;
  }
  .attendance-log-table {
    .status.success {
      background-color: #e2f5ea;
      border-radius: 24px;
      padding: 4px 8px 4px 4px;
    }
    .status.failed {
      background-color: #f9e6e6;
      border-radius: 24px;
      padding: 4px 8px 4px 4px;
    }
    .bold {
      font-weight: bold;
    }
    .icon.pointer {
      &.disabled {
        cursor: not-allowed;
      }
      cursor: pointer;
    }
  }
}
