.ui-datetime-display {
    text-decoration: underline dotted;
    white-space: nowrap;
}

.ui-datetime-display .datetime-label {
    margin:0 0 0 3px!important;
    vertical-align: text-bottom!important;
    padding: 3px!important;
}

.popup-time-table {
    text-transform: capitalize;
    font-size: 12px;
}

.popup-time-table .popup-time-tz {
    text-align: right;
    font-weight: bold;
}

.popup-time-table .popup-time-label {
    white-space: nowrap;
    padding:0 4px 0 0;
    font-weight: bold;
}

.popup-time-table .popup-time {
    white-space: nowrap;
    text-align: right;
}
