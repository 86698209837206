@use '../../../styles/scss/wonderschool/variables';

.fee-disclaimer {
  color: variables.$darker-gray;
  font-family: Moderat;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}
