@use '../../../../../styles/scss/wonderschool/variables';

.grid-header {
  padding: 0 7px;
}

.grid-header > * {
  background-color: variables.$almostWhite;
  font-weight: bold;
  padding: 10px 5px;
}

.invoice-total .inner {
  text-align: right;
  font-size: 18px;
  padding-right: 20px !important;
}

.invoice-total strong {
  font-size: 20px;
  color: #000;
}
.invoice-total .error {
  color: #9f3a38 !important;
}

.invoice-total .error strong {
  color: #9f3a38 !important;
}

.invoice-total-error {
  color: #9f3a38;
  font-weight: bold;
}

.discount-row {
  align-items: center;
  background-color: #eff1f5;
  padding: 20px 0;
}
.discount-delete-button {
  text-align: right;
}
.discount-delete-button .ui.button {
  margin-bottom: 1px;
}
.discount-amount {
  color: #db2828;
  font-size: 18px;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .hide-headers-mobile .field {
    display: none !important;
  }
}
