@use '../../../../styles/scss/wonderschool/variables';

.fs-mt-20 {
  margin-top: 20px;
}

.fs-error div {
  border-color: #e0b4b4 !important;
  background: #fff6f6;
  color: #9f3a38;
}

.fs-bold {
  font-weight: bold;
}

.table-container {
  max-height: 35vh;
  overflow: auto;
  border: 1px solid variables.$grey-shade-medium;
  box-shadow: none;
  border-radius: 0.28571429rem;
}

.frozen-table {
  table-layout: fixed;
  width: 100%;
}
.frozen-table th {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 2;
}

.frozen-table th,
.frozen-table td {
  border-left: none !important;
}

.frozen-cell,
.frozen-cell-header {
  position: sticky;
  top: 0;
  z-index: 1;
  left: 0;
  border-right: 1px solid variables.$grey-shade-medium !important;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-dark {
  td {
    background: #f1f1f1 !important;
  }
}

.table-row:nth-child(odd) {
  td {
    background: variables.$white;
  }
}

.table-row:nth-child(even) {
  td {
    background: variables.$light-red;
  }
}

.frozen-cell-header {
  z-index: 3 !important;
}

.table-cell {
  text-align: right !important;
  box-sizing: border-box;
  width: 100px;
}

.fs-filter-container {
  display: contents;
}

.card-amount {
  font-weight: 700;
  font-size: 18px;
}
.card-label {
  font-size: 0.8em;
}
.fs-summary {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  div {
    width: 100%;
    height: 69px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.5;
  }
  .negativeCard {
    background-color: variables.$light-pink;
    border: 1px solid variables.$light-outline-pink;
  }
  .normalCard {
    background-color: variables.$light-gray;
    border: 1px solid variables.$grey-outline-shade;
  }
  .positiveCard {
    background-color: variables.$light-green;
    border: 1px solid variables.$light-outline-green;
  }
}
.fs-summary-description {
  margin-top: -10px;
  font-family: Moderat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: variables.$darker-gray;
}
.filter-selection {
  margin: 30px 0;
}

.fs-report-header {
  border-bottom: 2px solid #d9d9d9;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}

.fs-report-action {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fs-action-text {
  color: #287bbb !important;
  font-weight: 700;
  font-size: 16px;
  display: inline-flex !important;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  background: #fff !important;
  padding: 0 !important;
}

.fs-hide-web-screen {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .fs-filter-container {
    display: block;
  }
  .fs-filter-mt-10 {
    margin-top: 10px;
  }
  .fs-hide-small-screen {
    display: none;
  }
  .fs-hide-web-screen {
    display: flex;
  }
  .fs-summary {
    display: block;
    width: 100%;
    div {
      margin-bottom: 10px;
    }
  }
}
