@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

:root.light {
	background-color: theme("colors.white");
}

:root.dark {
	background-color: theme("colors.gray.900");
	color: theme("colors.white");
}
svg{
	display:inline-block;
}

a {
	/* https://stackoverflow.com/questions/69203832/tailwind-links-do-not-have-the-default-cursor-pointer-on-hover */
	@apply cursor-pointer;
}

.ui.grid {
	/* overrides the tailwind grid display with semantic's (which is flex) */
	display: flex !important;	
}