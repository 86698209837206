@use '../../styles/scss/wonderschool/variables';

.attendanceTableHeader {
  background-color: variables.$almostWhite;
  font-weight: 800;
  padding: 10px 5px;
}
.attendanceTableEmpty {
  font-size: 12px;
  text-align: left;
}
