.setup-payout-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.setup-payout-modal-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.setup-payout-modal-description > h2 {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #484848;
  margin: 0;
}

.setup-payout-modal-description > p {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 140% !important;
  text-align: center;
  color: #484848;
}
