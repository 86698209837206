// Moxit Color system
$white: #fff !default;
$almostWhite: #ebeff4 !default;
$darkest-gray: #111 !default;
$dark-gray: #333 !default;
$darker-gray: #6d6d6d !default;
$gray: rgb(119, 119, 119) !default;
$light-gray: rgb(247, 247, 247) !default;
$lighter-gray: rgba(0, 0, 0, 0.05) !default;
$black: rgb(0, 0, 0) !default;
$blue: rgb(36, 111, 169) !default;
$dark-blue: #28325a !default;
$yellow: #ffbd33 !default;
$red: #f9461c !default;
$green: #00a91c !default;
$green-bg: rgb(225, 245, 234, 100) !default;

$primary: #28325a !default;
$secondary: #f4f5f7 !default;

$bgHighlight: rgba(252, 186, 3, 0.8);
$bgHighlightHover: rgba(252, 186, 3, 1);

$default_font: 'Moderat', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// New colors introduced in Center Overview
$mint: #ccf3eb;
$rose: #edd4ed;
$peach: #ffe5ad;
$moon: #d9ddf8;
$champagne: #fadfcc;
$red-light-shade: #f9e6e6;
$lighter-shade: #f1f1f1;
$status-start-color: #287bbb;
$darkest-gray: #484848;
$light-red: #f8f8f8;
// New Icon colors defined to represent different states.
$lighter-gray-white: #f4f4f4;
$light-outline-gray: #8a8a8a;

$lighter-green: #e2f5ea;
$light-outline-green: #6FCF97;

$light-yellow: #fff2d6;
$light-outline-yellow: #ffbd33;

$bright-red: #C10000;
$lighter-gray: #b6b6b6;
$bright-green:#00C49A;
$black-text: #242424;

// colors introduced for Finance at Glance
$light-yellow-1: #FFF5CC; 
$light-outline-yellow-1: #FFD785;
$light-violet: #ECEEFC;
$light-outline-violet: #D9DDF8;
$light-violet-1: #ECEEFC;
$light-green: #E2F5EA;
$light-outline-green: #C5ECD5;
$light-pink: #FBD1CE;
$light-outline-pink: #F7A39E;
$grey-shade: #F1F1F1;
$grey-outline-shade: #D9D9D9;
$grey-shade-medium: #C1C1C1;
