.child-option div {
  margin-left: 16px !important;
}

.parent-option {
  border-top: 1px solid #c1c1c1;
  label {
    font-weight: 700 !important;
  }
}

.select-all-option {
  label {
    font-weight: 700 !important;
  }
}

.checkbox-select-dropdown {
  position: relative;
  display: inline-block;
  width: 248px;
}

.dropdown-header {
  cursor: pointer;
  display: flex;
  height: 36px;
  min-height: 36px;
  max-height: 40px;
  padding: 8px 8px 8px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #c1c1c1;
  background: #fff;
  overflow: hidden;
}

.dropdown-header span {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.down-arrow {
  height: auto;
  width: auto;
  flex-shrink: 0;
  margin-left: auto; /* Move the image to the right */
}

.dropdown-options {
  display: flex;
  width: 248px;
  height: 240px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #c1c1c1;
  background: #fff;
  z-index: 4;
  overflow-y: scroll;

  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-option {
  cursor: pointer;
  background: #fff;
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.dropdown-option label {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
}
