.select-employees-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
  z-index: 20;
}

.select-employees-row > h2 {
  margin: 0 !important;
}

@media (min-width: 768px) {
  .select-employees-row {
    flex-direction: row;
    justify-content: space-between;
    gap: 0rem;
    padding-right: 0;
  }

  .select-employees-row > div:has(input) {
    width: fit-content !important;
  }
}
