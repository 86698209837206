@use '../../styles/scss/wonderschool/variables';

.studentTableEditCell {
  text-align: right;
}

.studentDataPlaceholder {
  color: #346AF4;
  display: inline-block;
  text-align: left;
  padding-left: 3px;
  transform: scale(3, 0.5);
  -webkit-transform: scale(3, 0.5);
  -moz-transform: scale(3, 0.5);
  -ms-transform: scale(3, 0.5);
  -o-transform: scale(3, 0.5);
}

.studentContactCellDiv {
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.studentAddedBannerWrap {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.studentPageTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.parentAvatarDimensions {
  max-width: 50px;
  padding-top: 0.5rem;
}
.invoicesListRow td {
  vertical-align: middle;
}

.studentStatusFilterBar {
  background: variables.$light-red;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
  margin: 0 0 1rem 0;
  display: flex;
  gap: 5px;
  .statusFilter {
    border-radius: 30px;
    font-size: 1rem;
    line-height: 1.4em;
    text-transform: uppercase;
    padding: 13px 30px;
    font-weight: 700;
    border: 1px solid variables.$light-red;
    color: white;
    &:hover {
      border: 1px solid #0B3DBB;
      cursor: pointer;
    }
    &:focus,
    &:active,
    &.active {
      background: #346AF4;
      color: white;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 980px) {
    flex-wrap: nowrap;
    padding: 8px;
    .statusFilter {
      padding: 4px 20px;
      text-align: center;
      font-size: 12px;
    }
    @media only screen and (max-width: 580px) {
      flex-direction: column;
      font-size: 11px;
    }
  }
}
.tag {
  padding: 4px 10px 4px 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  color: variables.$darkest-gray;
  font-size: 12px;
  justify-content: flex-start;
  svg {
    margin-left: 8px;
    margin-right: 6px;
    &::before {
      font-size: 14px;
    }
  }
  &.successTag {
    background-color: variables.$lighter-green;
    svg {
      color: variables.$bright-green;
    }
  }
  &.errorTag {
    background: variables.$lighter-gray-white;
    svg {
      color: variables.$light-outline-gray;
    }
  }
  &.pendingTag {
    background: variables.$light-yellow;
    svg {
      color: variables.$light-outline-yellow;
    }
  }
}
.showCount {
  color: variables.$darkest-gray;
  font-size: 14px;
  font-family: 'Moderat';
}
.customSpacer {
  padding: 0 1em;
}
.ml-0 {
  margin-left: 0;
}
button.actionText {
  color: #346AF4 !important;
  font-weight: 700;
  font-size: 16px;
  display: inline-flex !important;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  background: variables.$white !important;
  padding: 0 !important;
  &:last-child {
    margin-left: 28px;
  }
  &:focus {
    outline: 0 !important;
  }
}
.studentsTableHeader {
  background-color: variables.$almostWhite;
  font-weight: bold;
  padding: 10px 5px;
}
.studentArchived {
  color: variables.$red;
  font-style: italic;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
