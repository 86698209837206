$onboarding-banner-background-color: #fffaf0;
$status-incomplete-color: #946400;
$status-complete-color: #167e42;
$status-start-color: #287bbb;

.onboarding-banner {
  background-color: $onboarding-banner-background-color;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 1em 1.5em;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%;
  transition: all 0.5s ease-in-out;
  h1 {
    margin: 0 0 16px 0;
  }
  & .onboarding-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    transition: all 0.5s ease-in-out;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  & .onboarding-banner-header {
    h5 {
      margin-top: 0;
    }
    @media only screen and (max-width: 1080px) {
      h1 {
        font-size: 1.625rem;
      }
    }
    .onboarding-inner-wrapper {
      display: flex;
      justify-content: center;
    }
    @media only screen and (max-width: 767px) {
      .sunny-cloudy-image,
      > p {
        display: none;
      }
    }
  }

  & .onboarding-banner-footer {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 50%;
    justify-content: space-between;
    width: 100%;

    & > .sunny-left-image {
      display: block;
      min-width: 79px;
      max-height: 79px;
      transform: scaleX(-1);
    }

    & > .footer-text {
      align-self: center;
    }
    & > .toggle {
      align-self: center;
      cursor: pointer;
      flex-basis: auto;
    }
  }

  & .check-button {
    background-color: white;
    border-color: $status-start-color;
    border-radius: 8px;
    border-style: solid;
    border-width: 1.5px;
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 50%;
    gap: 8px;
    justify-content: space-between;
    padding: 16px 16px 16px 24px;

    &.complete {
      border-color: $status-complete-color;
      color: $status-complete-color;
    }

    &.incomplete {
      border-color: $status-incomplete-color;
      color: $status-incomplete-color;
      cursor: pointer;
    }

    &.start {
      border-color: $status-start-color;
      color: $status-start-color;
      cursor: pointer;
    }

    & > .marker {
      position: absolute;
      margin-left: -37px;
    }

    & > .onboarding-label {
      align-self: flex-start;
    }
  }
  & .ui.segment.buttons-section {
    @media only screen and (min-width: 769px) {
      margin-top: 0px;
      background: none;
      border: none;
      box-shadow: none;
      width: 45%;
    }
    p {
      font-size: 20px;
    }
  }
  &.expanded {
    & .onboarding-banner-footer {
      border-top: 1px solid #cbcbcb;
      @media only screen and (max-width: 767px) {
        & {
          margin-top: 2em;
        }
      }
      & > .footer-text {
        color: $status-start-color;
        cursor: pointer;
      }
    }
  }
  .ui.grid > .column.row > [class*='twelve wide'].column,
  .ui.grid > .row > [class*='twelve wide'].column {
    width: 100% !important;
  }
}
