.cardWidthFix {
    /* using !important as semantic and css modules don't play well together */
    width: 100% !important;
}

.cardContentBorderless {
    /* using !important as this is how semantic overrides borders */
    border-top: none !important;
}

.cardContentSpacer {
    padding-bottom: 0 !important;
}
.customspacer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}