@use '../../../styles/scss/wonderschool/variables';

.tooltip {
    position: relative;
    cursor: pointer;
  }

/* Show the tooltip on hover */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltiptext {
  padding: 8px;
  border-radius: 5px;
  gap: 10px;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  position: absolute;
  width: 100%;
  left: 0%;
  bottom: 100%;
  margin-bottom: 15px;
  /* Hide the tooltip by default */
  visibility: hidden;
  opacity: 0;
  background: linear-gradient(0deg, variables.$white, variables.$white),
  linear-gradient(0deg, variables.$lighter-gray, variables.$lighter-gray);
  box-shadow: 0px 2px 4px 0px #0000001F;
  border: 0.5px solid variables.$lighter-gray;
  z-index: 10;
}

/* The bordered part of the triangle */
$border-width: 10px;

.tooltiptext::before,
.tooltiptext::after {
  content: "";
  position: absolute;
  border-left: $border-width solid transparent;
  border-right: $border-width solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -$border-width;
}

.tooltiptext::before {
  border-top: $border-width solid variables.$lighter-gray;
  margin-top: 0px;
}

/* The white fill of the triangle */
.tooltiptext::after {
  border-top: $border-width solid variables.$white;
  margin-top: -1px;
  z-index: 1;
}
