.payout-setup-banner-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 2.25rem;
}

.payout-setup-banner-close {
  position: absolute;
  top: 3px;
  right: 3px;
}

.payout-setup-banner-close > button {
  margin: 0px !important;
  border: none !important;
  background-color: transparent !important;
}

.payout-setup-banner-title {
  text-align: center;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
}

.payout-setup-banner-title.regular {
  font-weight: 400 !important;
}

.payout-setup-banner-title.left-align {
  text-align: left;
}

.payout-setup-banner-link {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #287bbb;
}

@media (min-width: 768px) {
  .items-grid {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

@media (min-width: 1024px) {
  .items-grid {
    min-width: 700px;
  }
}
