@use '../styles/scss/wonderschool/variables';

.tag {
  padding: 4px 10px 4px 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  color: variables.$darkest-gray;
  font-size: 12px;
  justify-content: flex-start;
  svg {
    margin-left: 8px;
    margin-right: 6px;
    &::before {
      font-size: 14px;
    }
  }
  &.errorTag {
    background: variables.$lighter-gray-white;
    svg {
      color: variables.$light-outline-gray;
    }
  }
  &.invitedTag {
    background: variables.$light-yellow;
    svg {
      color: variables.$light-outline-yellow;
    }
  }
  &.invitingTag {
    background: variables.$light-yellow;
    svg {
      color: variables.$light-outline-yellow;
    }
  }
  &.pendingTag {
    background: variables.$lighter-shade;
    svg {
      color: variables.$lighter-gray;
    }
  }
  &.successTag {
    background-color: variables.$lighter-green;
    svg {
      color: variables.$bright-green;
    }
  }
}
