.checkbox-label {
  padding-left: 1rem;
}

.ws-form-title,
.ws-form-buttons {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 50%;
  justify-content: space-between;
  align-items: flex-start;
}
.ws-view-form {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width:100%;
  gap: 0.5rem;
  & .row {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 50%;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
    & .label, label {
      white-space: wrap;
      font-weight: 600;
      width: 50%
    }
    & .value {
      align-self: flex-start;
      font-weight: 300;
    }
  }
}
.invitation-recipients-view {
  & .invitation-recipient {
    border-bottom: 1px dashed $gray;
    padding: 0.5rem 0;
    &:last-child {
      border-bottom: none;
    }
  }

}
.invitation-recipients-form {
  & .invitation-recipient {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    & .details {
      padding-left: 1rem;
    }
    & .name {
      margin-bottom:0;
    }
  }
}

.parents-form,
.tuition-fees-form {
  &.ui.form {
    & .fields.fee-row {
      align-items: flex-end;
    }
  }
}

.ws-icon-button {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  & .ui.icon.button,
  &.ui.icon.button {
    border: none;
    box-shadow: none;
    padding-left: 0;
  }
}

.schedule-form {
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  & .schedule-title {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: flex-start;
  }
  & .schedule-items {
    display: flex;
    flex-flow: column wrap;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
  }
  & .schedule-item {
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
  }

  & .schedule-day {
    width: 33.33%;
  }
  & .schedule-time {
    width:33.33%;
    &:first-child {
      width: 100%;
    }
  }
}
.ws-icon-control,
.ws-icon-header,
.ws-icon-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.success-icon {
  color: $green;
}
.info-icon {
  color: $blue;
}