.ui.table.billing-summary tr th {
  vertical-align: middle;
}

.ui.table.billing-summary tr td {
  border-top-width: 0px;
  vertical-align: middle;
}

.invoice-note {
  white-space: normal !important;
}

.ws-fee-list-item-description-text {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.5);
}

.pending-update-status-modal {
  padding: 1rem;
  text-align: center;
}

.update-status-error {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui.button.update-status-error-close {
  margin-top: 1rem;
}

.confirm-send-modal {
  padding: 1rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sum-top-border {
  border-top: 4px solid #ebebeb !important;
}
