.employees-list.ui.selection.list > .item {
  padding: 20px;
}

.disabled-students {
  cursor: initial !important;
  background: #f2f2f2 !important;
  box-shadow: none !important;
  transform: none !important;
}
