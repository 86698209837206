.attendance {
  .bootstrap-iso .pagination {
    justify-content: flex-end;
  }

  .check-in {
    color: #00c49a;
  }
  .check-out {
    color: #00c49a;
  }
  .absent {
    color: #c10000;
    float: left;
  }
  .health-check {
    color: #2269ad;
    border-color: #2269ad;
  }
  .margin-top-0 {
    margin-top: 0 !important;
  }
  .check-in-out-bg {
    background-color: #f1f1f1;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
  }
  .create-report {
    padding-top: 100px;
    padding-bottom: 100px;
    p {
      font-weight: 700 !important;
      padding-top: 20px;
    }
  }
}
