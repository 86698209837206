.calculator {
  .bold {
    font-weight: bold;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ui.header.title {
    padding-left: 20px;
    margin-bottom: 24px;
    margin-top: 30px;
  }

  .container {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
  }

  .illustration {
    flex-shrink: 0;
    padding-right: 50px;
    padding-left: 75px;
  }

  .illustration-mobile {
    display: none;
  }

  .quote-author {
    color: #7E849C;
    font-weight: bold;
  }

  .bottom-text {
    margin-bottom: 30px;
    margin-top: 16px;
  }

  .calculator-section {
    border: 1px solid #CBCBCB;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .row-shadow {
    box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.2);
  }

  .row-title {
    margin-right: auto;
    margin-left: 13px;
    font-weight: bold;
  }

  .total-row {
    background-color: #ECEEFC;
    border-radius: 8px;
    border: none;
    padding: 17px 20px;
    margin-top: 10px;
  }

  .total-row.section-error {
    background-color: #F9E6E6;
  }

  .info-icon {
    margin-left: 14px;
    margin-right: 18px;
    padding-top: 5px;
  }

  .row-percent {
    margin-right: 18px;
  }

  .chevron-down {
    margin-bottom: 5px;
  }
  
  .section-body {
    padding: 0 20px 30px;
  }

  .section-gray {
    padding: 15px 20px;
    background-color: #F8F8F8;
    border-radius: 12px;
    margin-top: 24px;
    display: flex;
  }

  .section-gray.section-error {
    background-color: #F9E6E6;
  }

  .section-gray-bottom {
    margin-top: 8px;
  }

  .star-icon {
    margin-right: 8px;
  }

  .section-title {
    background-color: #ECEEFC;
    border-radius: 12px;
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 12px 24px;
  }

  .input-container {
    display: flex;
  }

  .input-section {
    display: flex;
    flex-direction: column;
    flex: 1;
    &.left {
      margin-right: 24px;
    }
  }

  .bottom-input {
    margin-top: 28px;
  }

  .bottom-input-margin {
    margin-top: auto;
  }

  .input-title {
    display: inline;
  }

  .input-title-icon {
    display: inline;
    margin-left: 5px;
  }

  .home-square-footage-input {
    width: 100%;
    margin-bottom: 20px;
  }

  .input-box.ui.input>input {
    margin-top: 12px;
    padding: 16px;
    border-radius: 8px;
  }

  .percentage {
    color: #287BBB;
  }

  .intro-paragraph {
    margin-bottom: 24px;
  }

  .disclaimer {
    font-size: 10px;
    margin-top: 24px;
  }

  .disclaimer-asterisk {
    font-size: 12px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .calculator {
    .ui.header.title {
      font-size: 20px;
      padding-left: 0;
      margin-top: 0;
    }

    .illustration {
      display: none;
    }

    .illustration-mobile {
      display: flex;
      justify-content: center;
      margin-bottom: 22px;
    }

    .container {
      padding-left: 0;
    }

    .top-text {
      font-size: 14px;
    }

    .quote-author {
      font-size: 14px;
    }

    .bottom-text {
      font-size: 14px;
      margin-bottom: 16px;
    }

    .row-title {
      font-size: 14px;
    }

    .input-container {
      display: block;
    }

    .section-body {
      font-size: 14px;
    }

    .section-title {
      display: none;
    }

    .input-section {
      margin-top: 24px;

      &.left {
        margin-right: 0;
      }
    }

    .row-percent-left {
      margin-right: 5px;
    }
  }
}
