.ui.menu {
  .item,
  .menu .item {
    font-family: $default_font;
  }
}

#main-menu {
  background: $light-gray;

  &.sidebar {
    box-shadow: none;
  }

  &.menu {
    .header {
      margin-bottom: 0;
    }

    .item {
      &.nav-item {
        display: block;

        &:hover {
          background: $lighter-gray;
        }

        &.has-children {
          &:hover {
            background: none;
          }
        }

        &.active {
          background: $lighter-gray;
        }

        .menu {
          a {
            &:hover {
              background: $lighter-gray;
              transition: 0.2s;
            }

            &.active {
              background: $darker-gray;
            }
          }
        }
      }
    }

    #location-picker {
      width: 100%;
    }

    a {
      color: inherit;
      width: 100%;
    }
  }
}

//override the semantic ui styles
.ui.styled.accordion.left-navigation {
  border-radius: 8;
  border: none;
  box-shadow: none;
  background: none;
}

.ui.styled.accordion.left-navigation {
  & .title {
    border: none;
    color: $darker-gray;
    &:hover {
      color:$darkest-gray
    }
  }
  & .active.title {
    color: $darkest-gray;
  }
  & .ui.vertical.menu {
    border-radius: 8px 0px 0px 8px;
    box-shadow: none;
    & .item {
      padding: 10px 20px;
      &:first-child {
        padding-top:20px;
      }
      &:last-child {
        padding-bottom:20px;
      }
    }

  }
}
/* Media Queries */
/* Mobile */
@media only screen and (min-width: 768px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large screen only']:not(.mobile),
  [class*='widescreen only']:not(.mobile),
  [class*='or lower hidden'] {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large screen only']:not(.mobile),
  [class*='widescreen only']:not(.mobile),
  [class*='or lower hidden'] {
    display: none !important;
  }

  #main-menu {
    &.menu {
      .item {
        &.has-children {
          .header {
            padding: 1rem 0;
          }
        }
      }
    }
  }
}
