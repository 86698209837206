.accordionTitle {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  gap: 8px;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  width: 100%;
}

.icon {
  align-self: center;
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
}

.title {
  align-self: center;
  flex: 1 1 auto;
}