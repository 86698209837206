@use '../../../styles/scss/wonderschool/variables';

.invite-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.actionIconBtn {
  span {
    display: inline-flex;
    gap: 10px;
    justify-content: flex-end;
    margin-left: 10px;
    a {
      cursor: pointer;
    }
    svg {
      color: variables.$status-start-color;
    }
  }
}
.checkmark {
  color: variables.$bright-green;
}
.close {
  color: variables.$bright-red;
}
