.modal-body {
  display: flex;
  padding: 32px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
}

.contact-list-container {
  width: 95%;
  padding: 5px 10px 10px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.contact-list-container::-webkit-scrollbar {
  display: none;
}

.ui.card.contact-card {
  width: 100%;
  margin-bottom: 32px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: black;
  border-color: #2185d0;
  overflow-wrap: break-word;

  &.card-selected {
    border-style: solid;
  }
  &.card-disabled {
    opacity: 0.7;
  }
  &:hover {
    border-color: #2185d0;
  }
}

.input-container {
  display: flex;
  justify-content: flex-end;
}

.ui.card.input-card {
  width: 80%;
  padding: 10px;
  align-self: flex-start;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
  }
}
