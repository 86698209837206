.enrolled-families {
  & .sunny-container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center
  }
  & p {
    margin: 0;
  }
  & h5 {
    margin: 0;
  }
  & .app-store-badges {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 1em;
    gap: 1em;
  }
}