@use '../../../styles/scss/wonderschool/variables';

.boldFont {
  font-weight: 700;
  font-size: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.label {
  font-size: 0.8em;
}

.cursorPointer {
  cursor: pointer;
}

.titleFont {
  font-size: 20px;
}

.textFontSize {
  font-size: 16px;
}

.disabledTextColor {
  color: variables.$lighter-gray;
}

.monthTitle {
  width: 165px;
  text-align: center;
  display: inline-block;
}

.financeAtGlance {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.financeAtGlance div {
  width: 100%;
  height: 69px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.5;
}

.refreshIcon {
  display: inline-flex;
  width: auto;
  margin-left: 5px;
}

@media only screen and (max-width: 1024px) {
  .financeAtGlance {
    flex-direction: row;
    flex-wrap: wrap;

    div {
      width: 49%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .financeAtGlance {
    width: 100%;

    div {
      width: 48.5%;
    }
  }

  .invoiceHeader {
    width: 100% !important;
    text-align: right !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .financeAtGlance {
    .titleFont {
      font-size: 16px;
    }

    div {
      width: 48%;
    }
  }
}
