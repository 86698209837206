@use '../../styles/scss/wonderschool/variables';

.carousel {
  width: calc(100% + 2rem);
  margin: -1rem;
}

.carouselNav,
.carouselNavIcon {
  padding: 0 !important;
  margin: 0 !important;
}

.carouselNavButton {
  padding: 0;
  margin: 0;
  border: 0;
  background: 0;
}

.carouselSlider {
  padding: 0 0.5rem;
}

.room {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h4 {
    min-height: 50px;
    max-height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
  }
}

.roomCount {
  font-size: 3rem !important;
  font-weight: bold !important;
  letter-spacing: -1px;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  text-align: center;
}

.roomHeading {
  min-height: 68px;
}

.roomHeadingWrapper {
  min-height: 22px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.roomTitle {
  margin-bottom: 0 !important;
  line-height: 24px !important;
}

.roomSubtitle {
  line-height: 20px;
}

.countWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.countHeading {
  font-weight: 700;
  font-size: 24px;
}

.roomAvatars {
  display: flex;
}
.roomAvatarsWrap {
  display: flex;
  justify-content: flex-end;
  min-height: 48px;
}
.roomRatioWrap {
  display: flex;
  justify-content: space-between;
}

.roomAvatar + .roomAvatar {
  margin-left: 0.25rem;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.mint {
  background-color: variables.$mint !important;
}

.rose {
  background-color: variables.$rose !important;
}

.peach {
  background-color: variables.$peach !important;
}

.moon {
  background-color: variables.$moon !important;
}

.champagne {
  background-color: variables.$champagne !important;
}
