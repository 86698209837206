
.table.documents-table {
  table-layout: auto;

  tr:not(:hover) {
    .action-btn-column{
      opacity: 0;
    }
  }
}
.student-name.with-icon {
  display: inline-block;
  margin-top: 0;
}

.is-unread {
  font-weight: 700;
  position: relative;

  td:first-child::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 8px;
    width: 8px;
    background-color: $green;
    border-radius: 50%;
    display: inline-block;
  }
}
