.invoice-archive-row,
.invoice-filter-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 1rem 0;

    @media only screen and (max-width: 768px) {
        margin-top: 4rem;
    }
}

.invoice-archive-row {
    padding-top: 0;
}

.invoice-filter-row .ui.selection.dropdown {
    min-width: 10rem;
}

.invoice-filter-row .ui.icon.input {
    min-width: 14rem;
}

.invoice-filter-row .filter-input {
    width: 10rem;
}

.date-range-picker div {
    width: 10rem;
}

.ui.basic.primary.button.archive-button:focus,
.ui.basic.primary.button.archive-button:hover,
.ui.basic.primary.button.archive-button {
  box-shadow: none!important;
  padding:8px;
}

.invoice-archived-label {
    color: red;
    font-style: italic;
}

.ui.header.invoice-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.enable-payments-modal {
    padding: 2rem 0;
    position: absolute;
    z-index: 11;
    width: 29rem;
    border-radius: 1.6rem;
    border: 1px solid #D9D9D9;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .top-text {
        padding: 0 1rem 1rem;
    }

    .link-section {
        margin-top: 1rem;
        border-top: 1px solid #D9D9D9;
        display: flex;
        justify-content: center;
        padding-top: 1.25rem;
    }

    .email-icon {
        margin-right: 0.5rem;
        margin-top: 0.2rem;
    }
}

.add-button-tooltip {
    display: flex;
}

.v2-label {
    position: absolute;
    right: 1rem;
}

@media only screen and (max-width: 768px) {
    .ui.header.invoice-header {
        flex-direction: column;
    }

    .ui.button.add-button {
        margin-right: auto;
        margin-top: 1rem;
    }

    .enable-payments-modal {
        width: auto;
        left: 1rem;
        right: 1rem;
    }
}
