.steps-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.steps-row {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.steps-box-wrapper {
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .steps-box-wrapper {
    width: 45%;
  }

  .steps-container {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}
