/* Hide the label on non-mobile screens */
.mobile-only-label {
  display: none !important;
}

/* Show the label only on screens with max width of 768px (mobile) */
@media only screen and (max-width: 768px) {
  .mobile-only-label {
    display: inline-block !important;
  }

  .mobile-margin-top {
    margin-top: 24px !important;
  }
}
