.side-modal {
}
.side-modal-overlay {
  z-index: 1000;
}

$blue: rgb(36, 111, 169) !default;

.link {
  background: transparent !important;
  padding: 0 !important;
  color: $blue !important;
}
