@use '../../styles/scss/wonderschool/variables';

.clock-wrap {
  display: flex;
  justify-content: space-between;
}
.align-checkbox {
  display: flex;
  grid-gap: 10px;
  .checkbox {
    margin-top: 5px;
  }
}
.tag {
  padding: 4px 10px 4px 0;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 24px;
  color: variables.$darkest-gray;
  font-size: 12px;
  &.successTag {
    background-color: variables.$lighter-green;
    svg {
      color: variables.$bright-green;
    }
  }
  svg {
    margin-left: 8px;
    margin-right: 6px;
  }
}
