.icon-right {
  margin-right: 0.5rem;
}

.email-icon {
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
}

.header-with-icon {
  padding-top: 4px;
  margin-bottom: 0;
}

.disabled-text {
  margin-left: auto;
  color: #C10000;
}

.enabled-text {
  margin-left: auto;
  color: #00C49A;
}

.ui.card>.content.content-no-border {
  border: none;
}

.link-section {
  margin-top: 2rem;
  padding-top: 1.25rem;
  border-top: 1px solid #D9D9D9;
}

.ui.card.disabled-section {
  background-color: #F8F8F8;
}

.ui.button.connect-button {
  margin-left: auto;
  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
  border: 1px solid #F1F1F1;

  &.enabled {
    background-color: #287BBB;
    color: white;
  }
}

.account-section {
  border-top: 1px solid #D9D9D9;
  padding-top: 1.75rem;
  display: flex;
  justify-content: space-between;
}

.edit-button {
  margin-left: auto;
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.ui.card.placeholder-padding {
  padding-bottom: 4rem;
}

.lock-icon {
  width: 24px;
  height: 24px;
}
